//import TableIndex from './TableIndex';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from './views/layouts/Layout';
import '@tremor/react/dist/esm/tremor.css';


const App = () => {
  return (
    <Layout />
  );
}

export default App;




