export const CONSULTAR_ORDEN_POR_ENVIO = 'CONSULTAR_ORDEN_POR_ENVIO';
export const LISTAR_ORDEN = 'LISTAR_ORDEN';
export const LISTAR_ORDEN_DETALLE = 'LISTAR_ORDEN_DETALLE';
export const REGISTRAR_ORDEN = 'REGISTRAR_ORDEN';
export const MODIFICAR_ORDEN = 'MODIFICAR_ORDEN';
export const REGISTRAR_ORDEN_DETALLE = 'REGISTRAR_ORDEN_DETALLE';
export const LISTAR_ARTICULO_DETALLE = 'LISTAR_ARTICULO_DETALLE';
export const MODIFICAR_ORDEN_DETALLE = 'MODIFICAR_ORDEN_DETALLE';
export const REGISTRAR_DETALLE_ARTICULO = 'REGISTRAR_DETALLE_ARTICULO';
export const ELIMINAR_DETALLE_HIJOS = 'ELIMINAR_DETALLE_HIJOS';
export const MODIFICAR_AVANCE_ORDEN = 'MODIFICAR_AVANCE_ORDEN';
export const ANULAR_ORDEN = 'ANULAR_ORDEN';

